import React from "react";
import Login from "@/components/Onboarding/Login";
import Head from "next/head"

export default function LoginPage(props) {
  return (
    <>
      <Head>
        <title>Login</title>
      </Head>
      <Login />
    </>
  );
}