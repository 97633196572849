import { basePath } from "next.config";
import React from "react";
import { ADMIN_GOOGLE_LOGIN_URL } from "services/url.service";

export default function Login() {
  const redirectToSSO = async () => {
    window.open(
      ADMIN_GOOGLE_LOGIN_URL(window.location.origin),
      "_self"
    );
  };

  return (
    <div className="d-flex w-100 h-100 justify-content-center align-items-center bg-body" style={{ minHeight: '100vh' }}>
      <div className="d-flex flex-column w-25">
        <div className="d-flex flex-column w-100 align-items-center" style={{ backgroundColor: '#EBEFFE', borderRadius: '12px 12px 0px 0px' }}>
          <div className="d-flex align-items-center justify-content-center bg-white rounded-circle mb-2" style={{ height: "5vw", width: "5vw", marginTop: '-2.5vw' }}>
            <img
              src={basePath + "/images/blissclub_logo.jpeg"}
              alt="logo"
              style={{ height: "95%", width: "95%" }}
            />
          </div>
          <span className="mb-2 text-primary" style={{ fontSize: '1.11vw' }}>Welcome Back !</span>
          <span className="mb-4 text-primary" style={{ fontSize: '0.902vw' }}>Sign in to continue to BlissClub!</span>
        </div>
        <div className="d-flex flex-column w-100 align-items-center bg-white" style={{ borderRadius: '0px 0px 12px 12px', paddingTop: '27px', paddingBottom: '35px' }}>
          <div className="d-flex cursor-pointer align-items-center justify-content-center bg-white w-75 p-2" onClick={redirectToSSO} style={{ borderRadius: '10px', boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168)' }}>
            <img
              src={basePath + "/images/google_logo.svg"}
              alt="google_logo"
              className="me-3"
              style={{ height: '1.66vw', width: '1.66vw' }}
            />
            <span className="text-secondary" style={{ fontSize: '1.38vw' }}>Sign In with Google</span>
          </div>
        </div>
      </div>
    </div>
  );
}
